import React, { useState, useEffect, useCallback, useContext, Fragment } from 'react';
import { Col, Input } from 'reactstrap';
import ListOfMenu from './Bookmark/ListOfMenu';
/* import EmpltyClass from './Bookmark/EmptyClass'; */
import CustomContext from '../../_helper/customizer';
import { Search } from 'react-feather';
import { SearchIconss } from '../../Data/svgIcons';
import {GetSearchWordApi} from '../../api/index.jsx';
import { SearchNoteApi} from '../../api/index.jsx';
import {HomeSvg, WidgetsSvg} from '../../Data/svgIcons';
const Searchbar = ({noteList, setNoteList, setNotes}) => {
  const [mainmenu, setMainmenu] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
	  const token = localStorage.getItem('token');
	  if (!token) {
		console.log("No token found!");
	  }

      const response = await fetch(`${GetSearchWordApi}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
	  const SearchWordData = await response.json();
	  setMainmenu(generateMenuItems(SearchWordData.data));
    };

    fetchData();
  }, []); // 空の配列を渡すことで、このフックは一度だけ実行されます

  const generateMenuItems = (SearchWordData, setSearchValue) => {
	// 'Tag'部分のメニューを生成
	const tagMenu = {
      menutitle: 'Tag',
      Items: [
		{
          title: 'tag',
          icon: HomeSvg,
          type: 'sub',
          badge: true,
          active: false,
          children: SearchWordData.tag.map(tag => ({
			title: tag,
			type: 'link',
			onClick: () => setSearchValue(tag)
          })),
		},
      ]
	};

	// 'Category'部分のメニューを生成
	const categoryMenu = {
      menutitle: 'Category',
      Items: [
		{
          title: 'category',
          icon: WidgetsSvg,
          type: 'sub',
          active: false,
          children: SearchWordData.category.map(category => ({
			title: category,
			type: 'link',
			onClick: () => setSearchValue(category)
          })),
		},
      ]
	};

	// 生成したメニューを配列にまとめて返す
	return [tagMenu, categoryMenu];
  };
  const [searchIcon, setSearchIcon] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchToggle, setSearchToggle] = useState(false);
  const [searchResult, setSearchResult] = useState(false);
  const { setIsClose } = useContext(CustomContext);
  const [IsOpen, setIsOpen] = useState(false);
  const OnhandelClick = () => setIsOpen(!IsOpen);
  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setSearchValue('');
      setSearchResult([]);
      setIsClose(false);
    }// eslint-disable-next-line
  }, []);
  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    let timer;
    console.log("search start");

    if (searchValue) {
      timer = setTimeout(() => {
		setSearchToggle(false);
        fetchSearchNote();
      }, 2500);
    };
	console.log(searchValue);
	return () => {
      document.removeEventListener('keydown', escFunction, false);
	  clearTimeout(timer);

    };
  }, [escFunction, searchValue, fetchSearchNote]);

  const fetchSearchNote = useCallback(async () => {
	const token = localStorage.getItem('token');
	if (!token) {
	  console.log("No token found!");
	  return;
	}

	try {
	  const response = await fetch(`${SearchNoteApi}`, {
		method: 'POST',
		headers: {
		  'Content-Type': 'application/json',
		  'Authorization': `Bearer ${token}`,
		},
		body: JSON.stringify({phrase: searchValue}),
	  });
	  const SearchList = await response.json();
	  setNotes(SearchList.data.notes);
	} catch (error) {
	  console.error("Failed to fetch notes: ", error);
	}
  },[searchValue]);



  const checkSearchResultEmpty = (items) => {
    if (!items.length) {
      setSearchIcon(true);
    } else {
      setSearchIcon(false);
    }
  };
  const addFix = () => {
    setSearchToggle(true);
  };
  const removeFix = (title) => {
    setSearchValue(title);
    setSearchToggle(false);
  };

  const handleSearchKeyword = (keyword) => {
    keyword ? addFix() : removeFix();
    const items = [];
    // 検索キーワードを小文字に変換
    const lowerKeyword = keyword.toLowerCase();
    setSearchValue(keyword);
    mainmenu.map(menuItems => {
      menuItems.Items.filter(mItems => {
        // 各タイトルも小文字に変換して比較
        if (mItems.title.toLowerCase().includes(lowerKeyword) && mItems.type === 'link') {
          items.push(mItems);
        }
        if (!mItems.children) return false;
        mItems.children.filter(subItems => {
          // 各タイトルも小文字に変換して比較
          if (subItems.title.toLowerCase().includes(lowerKeyword) && subItems.type === 'link') {
            subItems.icon = mItems.icon;
            items.push(subItems);
          }
          if (!subItems.children) return false;
          subItems.children.filter(suSubItems => {
            // 各タイトルも小文字に変換して比較
            if (suSubItems.title.toLowerCase().includes(lowerKeyword)) {
              suSubItems.icon = mItems.icon;
              items.push(suSubItems);
            }
            return suSubItems;
          });
          return subItems;
        });
        checkSearchResultEmpty(items);
        setSearchResult(items);
        return mItems;
      });
      return menuItems;
    });
  };

  return (
    <Fragment>
      <Col className="left-side-header ps-0 d-md-block dash-10">
        <div className="w-100 searchbar">
          <div className="resp-serch-box d-md-none resp-serch-input">
            <Search onClick={() => OnhandelClick(!IsOpen)} /></div>
            <div className={`input-group form-group search-form ${IsOpen ? 'open' : ''}`}>
              <span className='input-group-text'><SearchIconss /></span>
              <Input type="text" placeholder="Search here.."
                     value={searchValue}
                     onChange={(e) => handleSearchKeyword(e.target.value)} />
			  <ListOfMenu searchValue={searchValue} searchResult={searchResult} removeFix={removeFix} searchBar={true} searchToggle={searchToggle} />
			  {/*               <EmpltyClass searchIcon={searchIcon} search={true} /> */}
            </div>
        </div>
      </Col>
    </Fragment>
  );
};

export default Searchbar;
