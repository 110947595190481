import React, { Fragment , useState} from 'react';
import { Collapse, CardHeader,CardBody,  Col, Container, Row, Card } from 'reactstrap';
import { Btn, H5, H4, LI, P, UL } from '../../../AbstractElements';
import ListCard from './ListCard';
import {GetMarkdownApi} from '../../../api/index.jsx';
import RenderMarkdown from './RenderMarkdown.jsx';



const ListViewContain = () => {
  const [isFollowings, setisFollowings] = useState(true);
  const [markdown, setMarkdown] = useState("");

  const toggleClick = () => {
    setisFollowings(!isFollowings);
  };
  const styless = {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'block',
  };
  const handleCardClick = (note_id) =>{
	fetchMarkdown(note_id);
  }
  const fetchMarkdown = async (note_id) => {
	const token = localStorage.getItem('token');
	if (!token) {
	  console.log("No token found!");
	  return;
	}

	try {
	  const response = await fetch(`${GetMarkdownApi}?note_id=${note_id}`, {
		method: 'GET',
		headers: {
		  'Content-Type': 'application/json',
		  'Authorization': `Bearer ${token}`,
		},
	  });
	  const MarkdownData = await response.json();
	  setMarkdown(MarkdownData.data.article);
	} catch (error) {
	  console.error("Failed to fetch notes: ", error);
	}
  };

 
  const scrollStyle = {
    maxHeight: '1000px',
    overflow: 'auto'
  };


  return (
    <Fragment>
	  <Row>

		{/* 		<Col xl="4" lg="6" sm="6" md="6" className={'xl-50 box-col-6'}> */}
		<Col xl="3" lg="3" md="3" sm="12">

		  <Card>
			<CardHeader>
			  <H5 attrH5={{ className: 'p-0' }}>
				<Btn attrBtn={{
				  className: 'btn btn-link ps-0', databstoggle: 'collapse', databstarget: '#collapseicon4'
				  , ariaexpanded: 'true', ariacontrols: 'collapseicon4', color: 'transperant', onClick: toggleClick
				}} >Notes</Btn>
			  </H5>
			</CardHeader>
			<Collapse isOpen={isFollowings}>
			  <div style={scrollStyle}>
				<div className="collapse show" id="collapseicon8" aria-labelledby="collapseicon8" data-parent="#accordion">
				  <Container fluid={true}>
					<Row>
					  <ListCard onListCardClick={handleCardClick} />
					</Row>
				  </Container>
				</div>
			  </div>
			</Collapse>
		  </Card>
		</Col>
		{/* 		ここからメイン記事*/}
		{/* 		<Col xl="8" lg="12" md="7" className="xl-65"> */}
		  {/* 		  <Col xl="8" lg="6" sm="6" md="6" className="xl-65"> */}
		  <Col xl="9" lg="9" md="9" sm="12">

			<Card>
			  <CardBody>
				<div className="blog-details">
				  <UL
					attrUL={{
					  className: 'blog-social flex-row simple-list',
					}}
				  >
					<LI>{'25 July 2022'}</LI>
					<LI>
					  <i className="icofont icofont-user"></i>
					  {'Mark'} <span>{'Jecno'} </span>
					</LI>
					<LI>
					  <i className="icofont icofont-thumbs-up"></i>
					  {'02'}
					  <span>{'Hits'}</span>
					</LI>
					<LI>
					  <i className="icofont icofont-ui-chat"></i>
					  {'598 Comments'}
					</LI>
				  </UL>
				  <H4>
					タイトル
				  </H4>
				  <div className="single-blog-content-top">
					<RenderMarkdown markdown={markdown} />
				  </div>
				</div>
			  </CardBody>
			</Card>

			</Col>
	  </Row>
    </Fragment>
  );
};

export default ListViewContain;
