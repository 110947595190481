import React, { Fragment, useState, useEffect } from 'react';
import { Form, FormGroup, Input, InputGroup, InputGroupText, Label } from 'reactstrap';
import { Container, Row, Col, TabContent, TabPane } from 'reactstrap';
import { Btn, H5, UL } from '../AbstractElements';
import { EmailAddress, LoginWithJWT, Password, SignIn , RememberPassword, ForgotPassword} from '../Constant';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { firebase_app, Jwt_token } from '../Config/Config';
import man from '../assets/images/dashboard/1.png';
import { handleResponse } from '../Services/Fack.Backend';
import {LoginApi, GetUsernameApi} from '../api/index.jsx';

const FormPassword = () => {
  return (
    <Fragment>
      <FormGroup className="login-btn">
        <div className="checkbox">
          <Input id="checkbox1" type="checkbox" />
          <Label for="checkbox1">{RememberPassword}</Label>
        </div>
        <a className="link" href="#javascript">{ForgotPassword}</a>
      </FormGroup>
    </Fragment>
  );
};

const Logins = () => {
  const [selected, setSelected] = useState('firebase');
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);
  const navigate = useNavigate();
  const [value, setValue] = useState(
    localStorage.getItem('profileURL' || man)
  );
  const [name, setName] = useState(
    localStorage.getItem('Name')
  );

  useEffect(() => {
    localStorage.setItem('profileURL', value);
    localStorage.setItem('Name', name);
  }, [value, name]);

  const loginWithJwt = async (e) => {
	const requestOptions = {
	  method: 'POST',
	  headers: { 'Content-Type': 'application/json' },
	  body: JSON.stringify({ username: userId, password: password })
	};

	try {
	  const response = await fetch(LoginApi, requestOptions);
	  const data = await response.json();
	  localStorage.setItem('token', data.token);

	  const usernameResponse = await fetch(GetUsernameApi, {
		method: 'GET',
		headers: {
		  'Content-Type': 'application/json',
		  'Authorization': `Bearer ${data.token}`,
		},
	  });
	  const usernameData = await usernameResponse.json();

	  setValue(man);
	  setName(usernameData.data.username);
	  console.log(data);

	  navigate(`${process.env.PUBLIC_URL}/search`);

	  return data;
	} catch (error) {
	  // Handle any errors
	  console.error('Failed to login:', error);
	}
  };

  const callbackNav = ((select) => {
    setSelected(select);
  });

  return (
    <Container fluid={true} className="p-0">
      <Row>
        <Col xs="12">
          <div className="login-card">
            <div>
              <div className="login-main1 login-tab1">
                <TabContent activeTab={selected} className="content-login">
                  <TabPane className="fade show" tabId={selected === 'firebase' ? 'firebase' : 'jwt'}>
					<Fragment>
					  <Form className="theme-form login-form">
						<FormGroup>
						  <Label>User id</Label>
						  <InputGroup>
							<InputGroupText><i className='icon-email'></i></InputGroupText>
							<Input className="form-control" type="user_id" required="" onChange={e => setUserId(e.target.value)} defaultValue={userId} />
						  </InputGroup>
						</FormGroup>
						<FormGroup>
						  <Label>{Password}</Label>
						  <InputGroup>
							<InputGroupText><i className='icon-email'></i></InputGroupText>
							<Input className="form-control" type={togglePassword ? 'text' : 'password'} onChange={e => setPassword(e.target.value)} defaultValue={password} required="" />
							<div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}><span className={togglePassword ? '' : 'show'}></span></div>
						  </InputGroup>
						</FormGroup>
						<FormPassword />
						<FormGroup>
						  <Btn attrBtn={{ color: 'primary', className: 'btn-block', disabled: loading ? loading : loading, onClick: (e) => loginWithJwt(e) }} >{loading ? 'LOADING...' : LoginWithJWT}</Btn>
						</FormGroup>
					  </Form>
					</Fragment>

                  </TabPane>
                  <TabPane className="fade show" tabId="auth0">
                  </TabPane>
                </TabContent >
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Logins;
