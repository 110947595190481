import React, { Fragment, useContext , useState, useEffect} from 'react';

import { Card, Col,CardHeader, CardBody, Media } from 'reactstrap';
import JobSearchContext from '../../../_helper/JobSearch';
import { H6, Image, P, Ribbon , Btn} from '../../../AbstractElements';
import {GetNoteListApi} from '../../../api/index.jsx';

const TagButton = ({ tag, onTagClick }) => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive(!isActive);
    onTagClick(tag);
  };

  return (
	<Btn attrBtn={{className:"", color:"primary", size:"xs",active:{isActive}, outline:true  }}>
	  {tag}
	</Btn>
  );
};
const NoteCard = ({ item, onTagClick , onEachCardClick}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
      setIsHovered(true);
	};

	const handleMouseLeave = () => {
      setIsHovered(false);
	};
  const EachCardClick = (note_id) => {
	onEachCardClick(note_id);
  };
  const defaultClass = "";

  const singleLineText = {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  };

  return (
      <Col sm="12" xl="12">
		<Card className="b-r-0" style={{margin:0, borderRadius:0}} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={() => EachCardClick(item.note_id)}>
		  <CardBody className={isHovered ? "bg-primary" : defaultClass}>
			{/* 			<Ribbon attrRibbons={{ className: "ribbon ribbon-info ribbon-right"}} >{item.category}</Ribbon> */}
			<Ribbon 
			  attrRibbons={{ 
				className: "ribbon ribbon-info ribbon-right",
			  }}
			>
			  {item.category}
			</Ribbon>

			<div style={{
			  display: "-webkit-box",
			  WebkitLineClamp: 2,
			  WebkitBoxOrient: "vertical",
			  overflow: "hidden",
			  textOverflow: "ellipsis"
			}}>
			  <H6>{item.title}</H6>
			</div>
			<div style={{
			  display: "-webkit-box",
			  WebkitLineClamp: 3,
			  WebkitBoxOrient: "vertical",
			  overflow: "hidden",
			  textOverflow: "ellipsis"
			}}>
			  <P attrPara={{ className: 'mb-0' }}>
				{item.summary}
</P>
</div>
		  </CardBody>
		</Card>

		
			  {/* {item.tag.map((tag) =>
				  <TagButton key={tag} tag={tag} onTagClick={onTagClick} />
				  )} */}
			  {/* 		  </CardHeader> */}
		{/* 			  {item.summary} */}
      </Col>
  );
  };

  // ListCardコンポーネントではNoteCardを利用します
const ListCard = ({onListCardClick}) => {
  const [notes, setNotes] = useState([]);

	useEffect(() => {
      fetchNotes();
	}, []);
	const handleTagClick = (tag) => {
      console.log('Clicked tag: ', tag);
      // タグがクリックされた時の処理をここに追加します
	};
	const fetchNotes = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
		console.log("No token found!");
		return;
      }

      try {
		const response = await fetch(GetNoteListApi, {
          method: 'GET',
          headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${token}`,
          },
		});
		const notesData = await response.json();
		setNotes(notesData.data.notes);
		console.log(notesData.data.tag);
      } catch (error) {
		console.error("Failed to fetch notes: ", error);
      }
	};

	return (
      <React.Fragment>
		{notes.map((note) =>
		  <NoteCard key={note.note_id} item={note} onTagClick={handleTagClick} onEachCardClick={onListCardClick} />
		)}
      </React.Fragment>
	);
  };
  export default ListCard;
