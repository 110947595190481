import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import SearchViewContain from '../../Component/Search/index.jsx';

const SearchView = () => {
  return (
    <Fragment>
      <SearchViewContain />
    </Fragment>
  );
};
export default SearchView;
