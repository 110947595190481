import React from 'react';
import { marked } from 'marked';

const RenderMarkdown = ({ markdown ="" }) => {
  const createMarkup = () => {
    return { __html: marked(markdown) };
  };

  return <div dangerouslySetInnerHTML={createMarkup()} />;
};

export default RenderMarkdown;
