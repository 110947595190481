import ListView from '../Pages/Job Search/ListView';
import SearchView from '../Pages/Search/SearchView.jsx';
import SearchResult from '../Pages/Search Result';

export const routes = [
    {
      path: `${process.env.PUBLIC_URL}/jobSearch/job-list`, Component: <ListView />
    },
  {
    path: `${process.env.PUBLIC_URL}/Search`, Component: <SearchView />
  },
  { path: `${process.env.PUBLIC_URL}/searchresult`, Component: <SearchResult /> }
];
