import React, { Fragment , useState, useEffect} from 'react';
import { Collapse, CardHeader,CardBody,  Col, Container, Row, Card } from 'reactstrap';
import { Ribbon, Btn, H5, H4, LI, P, UL } from '../../AbstractElements';
import ListCard from './ListCard';
import {GetNoteListApi, GetMarkdownApi, SearchNoteApi} from '../../api/index.jsx';
import SearchBar from './SearchBar.jsx';
import Article from './Article.jsx';

const SearchViewContain = () => {
  const [isFollowings, setisFollowings] = useState(true);
  const [markdown, setMarkdown] = useState([]);
  const [noteList, setNoteList] = useState([]);
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    fetchNotes();
  }, []);

  const toggleClick = () => {
    setisFollowings(!isFollowings);
  };
  const styless = {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'block',
  };
  const handleCardClick = (note_id) =>{
	console.log(searchValue);
	fetchMarkdown(note_id);
  }

  const fetchNotes = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
	  console.log("No token found!");
	  return;
    }
	console.log("fetch note");

    try {
	  const response = await fetch(GetNoteListApi, {
        method: 'GET',
        headers: {
		  'Content-Type': 'application/json',
		  'Authorization': `Bearer ${token}`,
        },
	  });
	  const notesData = await response.json();
	  console.log(notesData);
	  setNotes(notesData.data.notes);
    } catch (error) {
	  console.error("Failed to fetch notes: ", error);
    }
  };


  const fetchMarkdown = async (note_id) => {
	const token = localStorage.getItem('token');
	if (!token) {
	  console.log("No token found!");
	  return;
	}

	try {
	  const response = await fetch(`${GetMarkdownApi}?note_id=${note_id}`, {
		method: 'GET',
		headers: {
		  'Content-Type': 'application/json',
		  'Authorization': `Bearer ${token}`,
		},
	  });
	  const MarkdownData = await response.json();
	  setMarkdown(MarkdownData.data);
	} catch (error) {
	  console.error("Failed to fetch notes: ", error);
	}
  };

 
  const [searchValue, setSearchValue] = useState('');

  const scrollStyle = {
    maxHeight: '1000px',
    overflow: 'auto'
  };

  return (
    <Fragment>
	  {/* 	  <Card> */}

		{/* 		<Row> */}
		  {/* 		  <Col xl="9" lg="9" md="9" sm="12"> */}
	  <SearchBar noteList={noteList} setNoteList={setNoteList} setNotes={setNotes} />
	  {/* </Col>
		  <Col xl="3" lg="3" md="3" sm="12">
		  <Btn attrBtn={{
		  className: "btn-pill",
		  color: "primary",
		  }} >
		  検索
		  </Btn>

		  </Col>
		  </Row> */}
			{/* 	  </Card> */}

	  <Row>
		{/* 		<Col xl="4" lg="6" sm="6" md="6" className={'xl-50 box-col-6'}> */}
		<Col xl="3" lg="3" md="3" sm="12">

		  <Card>
			<CardHeader>
			  <H5 attrH5={{ className: 'p-0' }}>
				<Btn attrBtn={{
				  className: 'btn btn-link ps-0', databstoggle: 'collapse', databstarget: '#collapseicon4'
				  , ariaexpanded: 'true', ariacontrols: 'collapseicon4', color: 'transperant', onClick: toggleClick
				}} >Notes</Btn>
			  </H5>
			</CardHeader>
			<Collapse isOpen={isFollowings}>
			  <div style={scrollStyle}>
				<div className="collapse show" id="collapseicon8" aria-labelledby="collapseicon8" data-parent="#accordion">
				  <Container fluid={true}>
					<Row>
					  <ListCard onListCardClick={handleCardClick} notes={notes} />
					</Row>
				  </Container>
				</div>
			  </div>
			</Collapse>
		  </Card>
		</Col>
		{/* 		ここからメイン記事*/}
		{/* 		<Col xl="8" lg="12" md="7" className="xl-65"> */}
		  {/* 		  <Col xl="8" lg="6" sm="6" md="6" className="xl-65"> */}
		  <Col xl="9" lg="9" md="9" sm="12">

			<Card>
			  <CardBody>
				<Article article_data={markdown} />
			  </CardBody>
			</Card>

		  </Col>
	  </Row>
    </Fragment>
  );
};

export default SearchViewContain;
