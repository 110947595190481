import React, { Fragment , useState, useEffect} from 'react';

import RenderMarkdown from './RenderMarkdown.jsx';
import TruncatedLink from './TruncatedLink.jsx';
import { Collapse, CardHeader,CardBody,  Col, Container, Row, Card } from 'reactstrap';
import { Ribbon, Btn, H5, H4, LI, P, UL } from '../../AbstractElements';

const TagButton = ({ tag, onTagClick }) => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive(!isActive);
    onTagClick(tag);
  };

  return (
	<Btn attrBtn={{className:"", color:"primary", size:"xs",active:{isActive}, outline:true  }}>
	  {tag}
	</Btn>
  );
};


const Article = ({article_data}) =>{
  const handleTagClick = (tag) => {
    console.log('Clicked tag: ', tag);
    // タグがクリックされた時の処理をここに追加します
  };


  return (

    <Fragment>
	  {article_data && <Ribbon 
						 attrRibbons={{ 
					 className: "ribbon ribbon-info ribbon-right",
				   }}
				>
		{article_data.category && article_data.category}
	  </Ribbon>}
	
	  {article_data && <div className="blog-details">

		<br />
		<i className="icofont icofont-thumbs-up"></i>
		{article_data.tag && article_data.tag.map((tag) =>
		  <TagButton key={tag} tag={tag} onTagClick={handleTagClick} />
		)}
		<br />
		<i className="icofont icofont-ui-chat"></i>
		<TruncatedLink url={article_data.clip_url} maxLength={50} />
		<P attrPara={{className: 'h2 txt-primary'}}>{article_data.title}</P>
		<div className="single-blog-content-top">
		  <RenderMarkdown markdown={article_data.article} />
		</div>
	</div>}
	</Fragment>

  );
};


export default Article;
