import React from 'react';

const TruncatedLink = ({ url="", maxLength }) => {
  const displayText = url.length > maxLength ? url.slice(0, maxLength) + '...' : url;

  return (
    <a href={url} target="_blank">
      {displayText}
    </a>
  );
};

export default TruncatedLink;
